@font-face {
  font-family: proxima-regular;
  src: url("./fonts/proxima/Proxima\ Nova\ Alt\ Regular.otf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: proxima-light;
  src: url("./fonts/proxima/Proxima\ Nova\ Alt\ Light.otf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: proxima-italic;
  src: url("./fonts/proxima/Proxima\ Nova\ Alt\ Regular\ Italic.otf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: proxima-bold;
  src: url("./fonts/proxima/Proxima\ Nova\ Alt\ Bold.otf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
