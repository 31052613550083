$primary-color-darkblue: #1d1f48; //rgb(29, 31, 72)
$primary-color-pacificblue: #00a1e8; //rgb(0, 161, 232)
$primary-color-gunmetal: #545859; //rgb(84, 88, 89)
$primary-color-lightgray: #d9d9d6; //rgb(217, 217, 214)

$secondary-color-orange: #ed8000; //rgb(237, 128, 0)

$default-primary-color: #3f51b5;
$default-warning-color: #f44336;
$text-color-white: #efefef;
$text-color-link: $default-primary-color;
$text-color-light: $primary-color-gunmetal;
$text-color-lighter: $primary-color-lightgray;
$text-color-dark: $primary-color-darkblue;

$button-background-color: $primary-color-gunmetal;
$button-text-color: white;
$button-text-color-blue: $default-primary-color;
$button-background-color__on-hover: #3c4041;

$desktop-max-width: 1200px;
$tablet-max-width: 900px;
$mobile-max-width: 540px;

$outline-border-color: rgba(0, 0, 0, 0.12);
$outline-border-color-hover: rgba(0, 0, 0, 0.5);

$error-color: #cc3300;

$link-color: $primary-color-pacificblue;

$font-regular: "proxima-regular";
$font-light: "proxima-light";
$font-bold: "proxima-bold";
$font-italic: "proxima-italic";
