//=================== MESSAGE ===================//
// These override indigo-pink style theme
//===============================================//

@import "./variables";

// Button
// background when hover
.mat-button:hover:not(.mat-button-disabled) .mat-button-focus-overlay,
.mat-stroked-button:hover:not(.mat-button-disabled) .mat-button-focus-overlay {
  opacity: 0.1 !important;
  border-radius: 3px;
}
// Mat icon
.mat-icon-button {
  height: 35px;
  line-height: 40px;
}

// Form
.mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 0.5em 0;
}
.mat-drawer-container {
  background-color: white;
}

// Menu dropdown
.mat-menu-panel {
  border-radius: 0 !important;
  ul {
    background-color: white;
  }
}
.mat-menu-content:not(:empty) {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.mat-form-field-label-wrapper {
  top: -18px;
}
.mat-form-field {
  width: 100%;
}
.mat-card-title {
  margin-bottom: 20px !important;
}
.mat-form-field-appearance-outline .mat-form-field-wrapper {
  margin: 0 !important;
}
.mat-success {
  color: green;
}

// Radio button
.mat-radio-button.mat-accent .mat-radio-inner-circle,
.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple),
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple,
.mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: $text-color-dark;
}
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: $text-color-dark;
}
.mat-radio-checked {
  .mat-radio-label-content {
    color: $text-color-dark;
    font-family: $font-bold;
  }
}

// Check box
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
.mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background: $primary-color-gunmetal;
}
.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
  background: $primary-color-gunmetal;
}

.cdk-overlay-connected-position-bounding-box {
  z-index: 9999;
}

// toggle
.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: $primary-color-darkblue;
}
.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(29, 31, 72, 0.54);
}

// Input
.mat-form-field input {
  font-size: 14px;
}
.mat-input-element {
  color: $text-color-dark !important;
}

.mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: black;
}

.mat-form-field textarea {
  font-size: 14px;
}

// Floating
mat-form-field.mat-form-field.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float
  .mat-form-field-label {
  transform: translateY(-1.6em) scale(1);
  width: max-content;
  top: 31px;
  background-color: white;
  padding-right: 5px;
  font-weight: bolder;
  font-size: 13px;
}

// Popup
.mat-dialog-container {
  padding: 10px 20px !important;
  position: relative;
  @media screen and (max-width: 540px) {
    padding-top: 20px !important;
  }
}
.close-button {
  position: absolute !important;
  right: 0px !important;
  top: 0px !important;
  z-index: 1;
  min-width: 24px !important;
  height: 24px !important;
  display: flex !important;
  line-height: 20px !important;
  align-items: center !important;
  padding: 0 !important;
  span {
    color: $text-color-dark;
  }
}

// Disable fields
.mat-form-field-appearance-outline.mat-form-field-disabled {
  .mat-form-field-outline {
    background-color: #e5e5e5;
    border-radius: 3px;
  }
  .mat-select-value-text {
    color: black;
  }
  .mat-form-field-label {
    color: black;
  }
}
.mat-select-value-text {
  display: block;
}
.mat-input-element:disabled {
  color: black;
}

// Error field
.mat-form-field-subscript-wrapper {
  margin-top: 0px;
}
.mat-error {
  font-size: 12px;
}
mat-form-field .mat-form-field {
  &-underline {
    position: relative;
    bottom: auto;
  }
  &-subscript-wrapper {
    position: static;
  }
}

// mat hint
.mat-hint {
  color: rgba(0, 0, 0, 0.75);
}

// mat expansion panel
// accessibility
.is-tabbing .booking-form {
  .mat-expansion-panel {
    :focus {
      outline: 1px solid;
    }
  }
}
.mat-expansion-panel-header.mat-expanded {
  height: 40px;
}
// fix content cut off
.mat-accordion > .mat-expansion-panel-spacing {
  overflow: inherit;
}
.mat-accordion > .mat-expansion-panel {
  overflow: inherit;
}
.mat-expansion-panel-header-title {
  color: $text-color-dark;
}

// ng-select
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
  background-color: $primary-color-gunmetal;
}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected {
  background-color: $primary-color-lightgray !important;
  color: black;
}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup.ng-option-selected {
  background-color: $primary-color-gunmetal;
  color: white;
}
.mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: transparent;
}

// mat select
.mat-select-panel {
  // max-width: initial !important;
  max-height: 500px !important;

  .mat-option-text {
    font-size: 13px;
    color: $text-color-dark;
  }
  .mat-option.mat-active {
    background: rgba(0, 0, 0, 0.12);
  }
}
.mat-select {
  .mat-select-value-text {
    span,
    div {
      color: $text-color-dark;
    }
  }
}
.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: $text-color-dark;
}

// mat autocomplete and option
.mat-autocomplete-panel {
  max-height: 500px !important;
}
.mat-option {
  font-size: 14px;
  height: 35px !important;
  line-height: 35px !important;
}

// mat tab
.mat-tab-label {
  opacity: 1 !important;
  .mat-tab-label-content {
    font-size: 18px;
    color: $text-color-dark;
  }
}
.mat-tab-group.mat-primary .mat-ink-bar,
.mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: $text-color-dark;
}

// card
.mat-card {
  font-family: $font-regular;
}
.mat-card:not(.mat-elevation-z0) {
  padding: 10px 20px;
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 30%), 0px 1px 1px 0px rgb(0 0 0 / 15%), 0px 1px 3px 0px rgb(0 0 0 / 60%) !important;
}
.mat-card.warning {
  background-color: #cc3300;
  color: white;
  padding: 10px 20px;
}
.mat-card[warning] {
  background-color: #cc3300;
  color: white;
  padding: 10px 20px;
}
.mat-card > .mat-card-actions:last-child {
  margin-bottom: 0px !important;
}
@media screen and (max-width: 768px) {
  .mat-card {
    padding: 5px !important;
  }
}

// Tooltip
.custom-tooltip {
  font-size: 15px !important;
  padding: 0 !important;
  min-width: 0 !important;
  min-height: 0 !important;
  margin-right: 5px !important;
}
.tippy-content {
  .label-value {
    div {
      color: white !important;
    }
  }
}

// =================== FORM WITH APPEARANCE FILL ======================//
.mat-form-field.mat-form-field-appearance-fill {
  .mat-form-field-label-wrapper {
    top: -0.84375em;
  }
  .mat-form-field input {
    font-size: 14px;
  }
  .mat-form-field-infix {
    padding: 0;
    padding-bottom: 2px;
    padding-top: 7px;
    border-top: 10px;
  }
  .mat-form-field-label {
    font-size: 14px;
    top: 0.9em;
    margin-top: -0.15em;
  }
}
